import React from "react";
import { get } from "lodash";
import { graphql } from "gatsby";
import HTML2React from "html2react";
import classNames from "classnames";
import NavOverlay from "../../components/NavOverlay/NavOverlay.js";
import ContactSection from "../../components/LandingPageContactSection/LandingPageContactSection.js";
import "./InfoPage.css";

const Page = ({ pageContext, data }) => {
  const dataObject = pageContext.pageContext;
  const title = get(dataObject, "title");
  const mainText = get(dataObject, "content");
  const image = get(dataObject, "infoPage.image.url");
  const sideText = get(dataObject, "infoPage.extraInfo");
  const sideTitle = get(dataObject, "infoPage.extraInfoTitle");

  const contactBackgroundImage = get(
    data,
    "wpgraphql.page.landingPage.forestImage2.sourceUrl"
  );
  const contactTitle = get(data, "wpgraphql.page.landingPage.contactTitle");
  const contactInfoParagraph = get(data, "wpgraphql.page.landingPage.contact");
  const contactEmail = get(data, "wpgraphql.page.landingPage.email");
  const contactPhone = get(data, "wpgraphql.page.landingPage.phone");
  const contactAddress = get(data, "wpgraphql.page.landingPage.address");

  return (
    <>
      <NavOverlay
        menuData={get(data, "wpgraphql.menu")}
        isInfoPage
        indexPageMenuData={get(data, "wpgraphql.altMenu")}
      />
      <div className="infoWrapper">
        <h1 className="infoTitle">{title}</h1>
        <div className="infoContent">
          {process.browser ? HTML2React(mainText) : null}
        </div>
        <img
          className={classNames("infoImage", !image && "hidden")}
          src={image}
          alt=""
        />
        <div className="infoSide">
          <p className="infoSideTitle">{sideTitle}</p>
          <p className="infoSideText">{sideText}</p>
        </div>
      </div>
      <ContactSection
        contactBackgroundImage={contactBackgroundImage}
        contactTitle={contactTitle}
        contactInfoParagraph={contactInfoParagraph}
        contactEmail={contactEmail}
        contactPhone={contactPhone}
        contactAddress={contactAddress}
        hidden={title !== "Ajalugu"}
      />
    </>
  );
};

export default Page;

export const query = graphql`
  query PageQuery {
    wpgraphql {
      menu(id: "bmF2X21lbnU6Mg==") {
        name
        menuItems {
          nodes {
            label
            id
            url
            parentId
            childItems {
              nodes {
                label
                url
                id
                parentId
              }
            }
          }
        }
      }
      page(id: "cG9zdDoy") {
        landingPage {
          contact
          contactTitle
          phone
          email
          address
          forestImage2 {
            id
            sourceUrl
          }
        }
      }
      altMenu: menu(id: "bmF2X21lbnU6Nw==") {
        name
        menuItems(first: 100, where: { parentId: "null" }) {
          nodes {
            label
            id
            childItems {
              nodes {
                label
                url
                id
                parentId
                childItems {
                  nodes {
                    label
                    url
                    id
                    parentId
                    childItems {
                      nodes {
                        label
                        url
                        id
                        parentId
                        childItems {
                          nodes {
                            label
                            url
                            id
                            parentId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
